<template>
	<div class="new-room">
		<div class="hb" style="margin:12px 16px 24px 22px">
			<div style="color: #3578FF ; height: 21px; display: flex;justify-content: flex-start;align-items: center;">
				<div style="display: flex" v-if="data.myLv === 1 || data.myLv === 2">
					<i class="el-icon-view"></i>
					<div style="font-size: 12px;color: #989898;margin-left: 5px">所有人可见</div>
				</div>
			</div>
			<div style="color: #3578FF ;height: 21px; display: flex;justify-content: flex-start;align-items: center;">
				<el-dropdown v-if="data.myLv === 1" @command="selectMenu">
					<div style="font-size: 12px;color: #989898;margin-right: 5px">管理 <i class="el-icon-s-unfold" style="font-size: 14px; color: #2970FF"></i></div>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item command="edit" >编辑Room信息</el-dropdown-item>
						<el-dropdown-item command="set" >设置Room菜单</el-dropdown-item>
						<el-dropdown-item command="group" >内部人员管理</el-dropdown-item>
						<el-dropdown-item command="order" >Room订单管理</el-dropdown-item>
						<el-dropdown-item command="money" >Room资金管理</el-dropdown-item>
						<el-dropdown-item command="sleep" v-if="data.status === 0">唤醒Room</el-dropdown-item>
						<el-dropdown-item command="sleep" v-else>沉睡Room</el-dropdown-item>
            <el-dropdown-item command="delete">删除Room</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>

				<div style="font-size: 12px;color: #989898;margin-right: 5px" v-if="data.myLv === 3"><i class="el-icon-success" style="color: #3578FF"></i> 内部成员</div>
				<div style="font-size: 12px;color: #989898;margin-right: 5px" v-if="data.myLv === 4"><i class="el-icon-remove-outline" style="color: #3578FF"></i> 非内部成员</div>
			</div>
		</div>
		<div class="hb hand op" style="padding: 0 17px" @click="toRoom(data.id)">
			<img class="room_head" :src="data.cover">
			<div class="room_name">{{data.name}}</div>
		</div>
		<div class="hb" style="margin-top:25px;background: #2970FF;height: 24px;border-bottom-left-radius: 5px;border-bottom-right-radius: 5px;box-sizing: border-box;padding: 0 20px;font-size: 12px;color: #FFFFFF;">
			<div>关注0</div>
		</div>
	</div>
</template>

<script>
	export default {
		name:"NewRoom",
		components: {

		},
        computed: { 
            user() { 
                return this.$store.state.user 
            },
            is_login(){
                return this.$store.state.is_login 
            }
        },
		data () {
			return {
				
			}
		},
		props:{
			data:{
				type:Object,
				default(){
					return {}
				}
			},
        },
		created:function(){

		},    
		mounted: function () {
		},
		methods:{
			// 进入room详情
			toRoom(id) {
				this.utils.a('/room/info/' + id)
			},

			// 菜单选择
			selectMenu(e) {
                if (e === 'edit') {
                    this.utils.a('/room/edit/' + this.data.id)
                }

				if (e === 'group') {
					this.utils.a('/room/member/' + this.data.id)
				}

				if (e === 'set') {
					this.utils.a('/room/menu/' + this.data.id)
				}

        if (e === 'delete') {
          let that = this;
          this.utils.confirm('Room删除后将无法恢复，是否确定删除此Room？', function () {
            that.newApi.delRoom({roomId: that.data.id}).then(res => {
              if (res.isSuccess === 1) {
                that.utils.sus(res.data)
                that.$parent.setRoomTab(0)
              }
            })
          })
        }

				if (e === 'sleep') {
					if (this.data.status === 0) {
						this.newApi.recoveryRoom({id: this.data.id}).then(res =>{
							if (res.isSuccess === 1) {
								this.$parent.getRoom()
							}
							this.utils.sus(res.data)
						})
					} else {
						this.newApi.sleepRoom({id: this.data.id}).then(res =>{
							if (res.isSuccess === 1) {
								this.$parent.getRoom()
							}
							this.utils.sus(res.data)
						})
					}
				}
			}

		}
	}

</script>

<style scoped>
.new-room{
	width: 100%;
	height: 146px;
	background: #FFFFFF;
	box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600);
	border-radius: 5px 5px 5px 5px;
	border: 1px solid #CBCBCB;
}
.hb{
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.room_head{
	width: 40px;
	height: 40px;
	background: #FFFFFF;
	border: 1px solid #707070;
	box-sizing: border-box;
	border-radius: 50%;
}
.room_name{
	width: calc( 100% - 45px);
	word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* 这里是超出几行省略 */
    overflow: hidden;
    color: #555555;
    font-weight: 600;
    font-size: 14px;
}
</style>