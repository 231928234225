<template>
    <div class="new">
        <div style="overflow:hidden;background-color: #f0f0f0;min-width: 1500px;">
            <div style="width: 1500px;margin:0 auto;overflow: hidden; margin-top: 98px">
                <div class="page_content" style="width:894px;height: auto;float: left;margin: 10px 10px 0 294px;min-height: 1000px;">
                    <div style="margin-bottom: 10px">
                        <el-input placeholder="请输入内容" v-model="content" class="input-with-select">
                          <el-select v-model="selectType" slot="prepend" style="width: 86px" placeholder="请选择">
                            <el-option label="全部" :value="0"></el-option>
                            <el-option label="自己" :value="1"></el-option>
                          </el-select>
                            <el-button class="hand op sc" style="width: 80px; text-align: center" @click="roomSearch" slot="append">
                                <img style="width: 20px; height: 20px;" src="./imgs/searchIcon.png">
                            </el-button>
                        </el-input>
                    </div>
                    <div style="height:auto;max-width: 900px;margin: 0 auto;" >
                        <div style="width: 100%;">
                            <div v-for="(item,index) in content_list" v-bind:key="index" style="width: 100%;height: auto;">
                                <template>
                                    <ContentALL :data="item"></ContentALL>
                                </template>
                            </div>
                            <div style="overflow:hidden;" v-if="show_skeleton">
                                <div v-for="(item,index) in 10" v-bind:key="index" style="width: 100%;height: auto;">

                                    <el-skeleton style="width: 90%;background-color: white;padding: 5%; margin-bottom: 10px;" :loading="show_skeleton" animated>
                                        <template slot="template">
                                            <div style="overflow:hidden;">
                                                <el-skeleton-item variant="image" style="width: 60px; height: 60px;border-radius:30px;float: left;"/>
                                                <div style="float:left;display: flex; align-items: center; justify-content: space-between;">
                                                    <el-skeleton-item variant="text" style="width: 50%;"/>
                                                    <div style="display: flex; align-items: center; justify-content: space-between; margin-top: 16px; height: 16px;">
                                                        <el-skeleton-item variant="text" style="width: 100px; margin: 12.5px 10px ; height:15px;"/>
                                                        <el-skeleton-item variant="text" style="width: 30px; margin: 12.5px 10px ; height:15px;" />
                                                    </div>
                                                </div>
                                            </div>
                                            <el-skeleton-item variant="image" style="width: 90%; height: 210px;padding : 0 5%; float: left;margin-top: 10px;"/>

                                            <el-skeleton-item variant="text" style="width: 50%;margin-top: 16px; " />
                                            <div style="display: flex; align-items: center; justify-content: space-between; margin-top: 16px; height: 16px;">
                                                <el-skeleton-item variant="text" style="margin-right: 16px;" />
                                                <el-skeleton-item variant="text" style="width: 30%;" />
                                            </div>

                                        </template>
                                    </el-skeleton>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="page_right" style="width:302px;float: right; margin-top: 10px">
                    <AskRight style="min-height: 100px"></AskRight>
                    <BusinessInformation style="min-height: 100px"></BusinessInformation>
                    <RecommendEvent v-if="is_login" style="min-height: 100px"></RecommendEvent>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import Head from "../../components/common/Head";
    import RoomNav from './components/RoomNav'
    import AskRight from './components/AskRight'
    import BusinessInformation from './components/BusinessInformation'
    import ContentALL from "./components/ContentAll";
    import RecommendEvent from "./components/RecommendEvent";
    import RoomLandingPage from "@/pages/index/roomLandingPage.vue";

    export default {
        name: "new",
        components: {
	        RoomLandingPage,
            RecommendEvent,
            ContentALL,
            Head,
            RoomNav,
            AskRight,
            BusinessInformation
        },
        computed: {
            user() {
                return this.$store.state.user
            },
            is_login(){
                return this.$store.state.is_login
            },
        },
        data() {
            return {
                page:1,
                more:1,
                content_list:[],
                show_skeleton:false,
                content:"",
                selectType: 0,
            }
        },
	    watch: {
		    is_login(val) {
			    if (val){
				    this.backTop();
				    this.get_content_list();
			    }
		    }
	    },
      created() {
        var that = this;
        window.onscroll = function(){
          //变量scrollTop是滚动条滚动时，距离顶部的距离
          var scrollTop = document.documentElement.scrollTop||document.body.scrollTop;

          //变量windowHeight是可视区的高度
          var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
          //变量scrollHeight是滚动条的总高度
          var scrollHeight = document.documentElement.scrollHeight||document.body.scrollHeight;
          if(scrollHeight - scrollTop - windowHeight <= 10){
            if (that.more == 1 && that.show_skeleton == false) {
              if(that.content === ''){
                that.get_content_list();
              }else{
                that.getSearchContent();
              }

            }else{
              console.log("已加载完");
            }
          }
        }
        this.$EventBus.$on("roomContentFresh", ({ }) => {
          this.$nextTick(() => {
            this.page = 1;
            this.more = 1;
            this.content_list = []
            this.get_content_list();
          });
        });
      },
      mounted(){
            var that = this;
            // this.id = this.$route.query.id;
            this.backTop();

	        if (this.is_login) {
            that.get_content_list();
            that.$nextTick(function () {
                that.$EventBus.$on('changeData', () => {
                    that.content_list = [];
                    that.page = 1;
                    that.more = 1;
                    that.get_content_list();
                });
            });

	        }
        },
        directives: {
            drag(el, bindings) {
                el.onmousedown = function(e) {
                    var disx = e.pageX - el.offsetLeft
                    var disy = e.pageY - el.offsetTop
                    document.onmousemove = function (e) {
                        el.style.left = e.pageX - disx + 'px'
                        el.style.top = e.pageY - disy + 'px'
                    }
                    document.onmouseup = function() {
                        document.onmousemove = document.onmouseup = null
                    }
                }
            }
        },
        methods:{
            backTop () {
                document.documentElement.scrollTop = document.body.scrollTop = 0;
            },
            roomSearch(){
              this.page = 1;
              this.more = 1;
              this.content_list = [];
              if(this.content == ''){
                this.get_content_list();
              }else{
                this.getSearchContent();
              }
            },
            getSearchContent(){
              var that = this;
              that.show_skeleton = true;
              this.newApi.roomSearch({
                content:this.content,
                pageNo:this.page,
                range:this.selectType,
                pageSize:10
              }).then(ret =>{
                for (var i = 0; i < ret.data.length; i++) {
                  that.content_list.push(ret.data[i]);
                }
                if (ret.data.length < 10) {
                  that.more = 0;
                }
                that.page++;
                that.show_skeleton = false;
              })
            },
            get_content_list:function(){
                var that = this;
                that.show_skeleton = true;

                var params = {};
                params.page=that.page;

                params.pageSize=10;
                this.newApi.getRecentContent(params).then(ret =>{
                    for (var i = 0; i < ret.data.length; i++) {
                      if (ret.data[i].menuType === 2) {
                        if (ret.data[i].content.images.length > 0) {
                          ret.data[i].menuType = 3
                        }
                        if (ret.data[i].content.videos.length > 0) {
                          ret.data[i].menuType = 4
                        }
                        if (ret.data[i].content.enclosure.length > 0) {
                          ret.data[i].menuType = 5
                        }
                      }
                        that.content_list.push(ret.data[i]);
                    }
                    if (ret.data.length < 10) {
                        that.more = 0;
                    }
                    // if(ret.data.length == 0 && that.page == 1){
                    //     that.a('/room/all');
                    // }
                    that.page++;
                    that.show_skeleton = false;
                })
            },
        }
    }
</script>

<style scoped>
    /deep/ .input-with-select .el-input-group__append {
        background-color: #2970FF;
        border: 1px solid #2970FF;
    }

    .el-input-group__append{
        background-color: #2970FF;
        color: #ffffff;
    }
    .el-input__inner{
        height: 45px;
    }
</style>
